import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
// import Nav from 'react-bootstrap/Nav'
import logo from '../images/logo.png'

function MyNavbar() {
    return (
        <Navbar collapseOnSelect expand = "lg" sticky = "top" bg = "white" variant = "light">
            <Navbar.Brand href = "#home">
                <img
                    alt = ""
                    src = {logo}
                    height = "40px"
                />
            </Navbar.Brand>
            {/* <Navbar.Toggle aria-controls = "response-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id = "response-navbar-nav">
            <Nav className = "ml-auto">
                <Nav.Link>
                    Contact
                </Nav.Link>
            </Nav>
            </Navbar.Collapse> */}
        </Navbar>
    );
}

export default MyNavbar;