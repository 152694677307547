import React from 'react';
import './App.css';
// import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import Alert from 'react-bootstrap/Alert'

import MyNavbar from './MyNavbar'
import AboutMe from './AboutMe'
import MyBots from './MyBots'
import Page from './Page';

class App extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      pages: []
    }
  }

  render() {
    return (
      <Container>
        <br/>
        {this.props.updateAvailable && (
          <Alert variant = "info">New content is available and will be used when all tabs for this page are closed</Alert>
        )}
        {this.props.contentCached && (
          <Alert variant = "success">Content is cached for offline use.</Alert>
        )}
        <MyNavbar/>
        <br/>
        {/* <br/><br/><br/><br/> */}
        <Tab.Container defaultActiveKey = "hello-tab">
          <Row>
            <Col sm = {3}>
              <Nav variant = "pills" className = "flex-column"  >
                <h4>Hello!</h4>
                <Nav.Item>
                  <Nav.Link eventKey = "hello-tab">About me</Nav.Link>
                </Nav.Item>
                <br></br>
                <h4>My Stuff</h4>
                <h6>Node.js Modules</h6>
                <Nav.Item>
                  <Nav.Link eventKey = "bot-util-tab">bot-util</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey = "node-p5-tab">node-p5</Nav.Link>
                </Nav.Item>
                <h6>Guides</h6>
                <Nav.Item>
                  <Nav.Link eventKey = "fb-bots-guide-tab">facebook bots guide</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey = "deply-bots-guide-tab">deploy bots guide</Nav.Link>
                </Nav.Item>
                <h6>Bots</h6>
                <Nav.Item>
                  <Nav.Link eventKey = "bots-list-tab">my bots</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey = "bas-tab">the bot appreciation society</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm = {9}>
              <Tab.Content>
                <Tab.Pane eventKey = "hello-tab">
                  <Page title = "AboutMe"/>
                  <AboutMe/>
                </Tab.Pane>
                <Tab.Pane eventKey = "bot-util-tab">
                  <Page title = "BotUtil"/>
                </Tab.Pane>
                <Tab.Pane eventKey = "node-p5-tab">
                  <Page title = "NodeP5"/>
                </Tab.Pane>
                <Tab.Pane eventKey = "fb-bots-guide-tab">
                  <Page title = "FacebookBotsGuide"/>
                </Tab.Pane>
                <Tab.Pane eventKey = "deply-bots-guide-tab">
                  <Page title = "DeployBotsGuide"/>
                </Tab.Pane>
                <Tab.Pane eventKey = "bots-list-tab">
                  <MyBots/>
                </Tab.Pane>
                <Tab.Pane eventKey = "bas-tab">
                  <Page title = "BasPage"/>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    );
  }
}

export default App;
