import React from 'react'
import ReactMarkdown from 'react-markdown';
import CodeBlock from './CodeBlock'

class Page extends React.Component {
    constructor() {
        super();
        this.state = {
            body: ''
        }
    }
    componentDidMount() {
        const path = require(`./Pages/${this.props.title}.md`);
        fetch(path).then(res => {
            return res.text();
        }).then(text => {
            this.setState({
                body: text
            });
        });
    }

    render() {
        return (
        <ReactMarkdown
            source = {this.state.body}
            renderers = {{ code: CodeBlock }}
        />)
    }
}

export default Page;