import React from 'react'
import Card from 'react-bootstrap/Card'
import CardColumns from 'react-bootstrap/CardColumns'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

import emojiBotBanner from '../images/emojibot_banner.png'
import garkovBanner from '../images/garkov_banner.png'
import text2SpeechBanner from '../images/t2s_banner.png'
import vsauceBanner from '../images/vsaucebot_banner.png'
import acronymBanner from '../images/acronym_banner.png'
import psychedelicBanner from '../images/psychedelic_banner.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from 'react-bootstrap/Collapse';
// import Container from 'react-bootstrap/Container';

class BotCardDescription extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false
        }
    }
    handleOnClick = () => {
        this.props.onClick(this.props.cardIndex);
        const open = this.state.open;
        this.setState({open: !open});
    }
    render() {
        return (
            <ListGroup className = "list-group-flush" style = {{boxShadow: "none", padding: "1px", paddingBottom: 0}}>
                <ListGroup.Item action onClick = {this.handleOnClick}>{this.state.open ? "Hide Description" : "Show Description"}</ListGroup.Item>
            </ListGroup>
        )
    }
}

class MyBots extends React.Component {
    constructor() {
        super();
        this.state = {
            cardDescriptions: Array(6).fill(false)
        }
    }
    onToggleCollapse = (index) => {
        let descs = this.state.cardDescriptions;
        descs[index] = !descs[index];
        this.setState({
            cardDescriptions: descs
        });
    }
    render() {
        const descriptions = this.state.cardDescriptions;
        return (
            <CardColumns>
                <Card>
                    <Card.Img src = {emojiBotBanner}></Card.Img>
                    <Card.Body>
                        <Card.Title>EmojiBot 101</Card.Title>
                        <Collapse in = {descriptions[0]}>
                            <Card.Text>Posts 1-5 random emojis every hour - fun for the whole family!</Card.Text>
                        </Collapse>
                    </Card.Body>
                    <BotCardDescription cardIndex = "0" onClick = {this.onToggleCollapse}/>
                    <Card.Footer>
                        <Button href = "https://www.facebook.com/emojibot101/" variant = "outline-primary" style = {{width: '100%'}}><FontAwesomeIcon color = "dark" icon = {['fab', 'facebook']}/> Check it out!</Button>    
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img src = {garkovBanner}></Card.Img>
                    <Card.Body>
                        <Card.Title>Garkov PostBot 1978</Card.Title>
                        <Collapse in = {descriptions[1]}>
                            <Card.Text>Posts randomly generated Garfield comics (from <a href = "http://joshmillard.com/garkov/">this site</a>)</Card.Text>
                        </Collapse>
                    </Card.Body>
                    <BotCardDescription cardIndex = "1" onClick = {this.onToggleCollapse}/>
                    <Card.Footer>
                        <Button href = "https://www.facebook.com/garkovpostbot/" variant = "outline-primary" style = {{width: '100%'}}><FontAwesomeIcon color = "dark" icon = {['fab', 'facebook']}/> Check it out!</Button>    
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img src = {acronymBanner}></Card.Img>
                    <Card.Body>
                        <Card.Title>AcronymBot0000</Card.Title>
                        <Collapse in = {descriptions[2]}>
                            <Card.Text>Posts randomly-generated acronyms for randomly-picked words</Card.Text>
                        </Collapse>
                    </Card.Body>
                    <BotCardDescription cardIndex = "2" onClick = {this.onToggleCollapse}/>
                    <Card.Footer>
                        <Button href = "https://www.facebook.com/acronmymbot/" variant = "outline-primary" style = {{width: '100%'}}><FontAwesomeIcon color = "dark" icon = {['fab', 'facebook']}/> Check it out!</Button>    
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img src = {text2SpeechBanner}></Card.Img>
                    <Card.Body>
                        <Card.Title>Text2SpeechBot 0010</Card.Title>
                        <Collapse in = {descriptions[3]}>
                            <Card.Text>Reads out loud the most liked comment on the last post</Card.Text>
                        </Collapse>
                    </Card.Body>
                    <BotCardDescription cardIndex = "3" onClick = {this.onToggleCollapse}/>
                    <Card.Footer>
                        <Button href = "https://www.facebook.com/t2sbot/" variant = "outline-danger" style = {{width: '100%'}}><FontAwesomeIcon color = "dark" icon = {['fab', 'facebook']}/> Dead</Button>    
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img src = {vsauceBanner}></Card.Img>
                    <Card.Body>
                        <Card.Title>VsauceBot Here</Card.Title>
                        <Collapse in = {descriptions[4]}>
                            <Card.Text>Takes a random frame from a Vsauce video and runs it through Google image recognition</Card.Text>
                        </Collapse>
                    </Card.Body>
                    <BotCardDescription cardIndex = "4" onClick = {this.onToggleCollapse}/>
                    <Card.Footer>
                        <Button href = "https://www.facebook.com/vsaucebot/" variant = "outline-danger" style = {{width: '100%'}}><FontAwesomeIcon color = "dark" icon = {['fab', 'facebook']}/> Dead</Button>    
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Img src = {psychedelicBanner}></Card.Img>
                    <Card.Body>
                        <Card.Title>PsychedelicPostBot1337</Card.Title>
                        <Collapse in = {descriptions[5]}>
                            <Card.Text>Generates psychedelic-looking images using perlin noise</Card.Text>
                        </Collapse>
                    </Card.Body>
                    <BotCardDescription cardIndex = "5" onClick = {this.onToggleCollapse}/>
                    <Card.Footer>
                        <Button href = "https://www.facebook.com/1337botpost/" variant = "outline-danger" style = {{width: '100%'}}><FontAwesomeIcon color = "dark" icon = {['fab', 'facebook']}/> Dead</Button>    
                    </Card.Footer>
                </Card>
            </CardColumns>
        )
    }
}

export default MyBots