import React from 'react'
import CardColumns from 'react-bootstrap/CardColumns'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import logoBig from '../images/my_social_medias.png'
import logoSmall from '../images/my_social_medias_mobile.png'
import { withGetScreen } from 'react-getscreen'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AboutMe extends React.Component {
    constructor() {
        super();
        this.state = {
            socialCards: [
                {
                    title: "My Github Page",
                    text: "My github page is probably getting replaced by this site so, uhh, yea!",
                    btnVariant: "outline-dark",
                    btnIconColor: "grey",
                    btnIcon: "github",
                    btnUrl: "https://andithemudkip.github.io"
                },
                {
                    title: "My Soundcloud",
                    text: "Where I post my music",
                    btnVariant: "outline-warning",
                    btnIconColor: "dark",
                    btnIcon: "soundcloud",
                    btnUrl: "https://soundcloud.com/the-aero-zero"
                },
                {
                    title: "My Youtube Channel",
                    text: "I post there once every few months - you can find songs, gameplays, random edits and a lot of nonsense.",
                    btnVariant: "outline-danger",
                    btnIconColor: "dark",
                    btnIcon: "youtube",
                    btnUrl: "https://www.youtube.com/channel/UCjSZlwS5-hb3RoifaAXupLw"
                },
                {
                    title: "My Twitter",
                    text: "Occasional tweets about tech - still trying to get recognized by Notch!",
                    btnVariant: "outline-primary",
                    btnIconColor: "lightskyblue",
                    btnIcon: "twitter",
                    btnUrl: "https://twitter.com/andithemudkip"
                },
                {
                    title: "My ArtStation",
                    text: "I don't really draw anymore but this is where I posted my drawings",
                    btnVariant: "outline-dark",
                    btnIconColor: "rgb(29, 135, 201)",
                    btnIcon: "artstation",
                    btnUrl: "https://www.artstation.com/andithemudkip"
                },
                {
                    title: "My Instagram",
                    text: "This is where I post the photos I think are artsy enough to be shared on the internet.",
                    btnVariant: "outline-danger",
                    btnIconColor: "dark",
                    btnIcon: "instagram",
                    btnUrl: "https://www.instagram.com/andithemudkip/"
                }
            ]
        }
    }
    render() {
        let logo = this.props.isMobile() ? logoSmall : logoBig;
        const cards = this.state.socialCards.map(card => {
            return (
                <Card key = {card.btnUrl}>
                    <Card.Body>
                        <Card.Title>{card.title}</Card.Title>
                        <Card.Text>{card.text}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Button href = {card.btnUrl || "#"} variant = {card.btnVariant}><FontAwesomeIcon color = {card.btnIconColor} icon = {['fab', card.btnIcon]}/> Check it out!</Button>    
                    </Card.Footer>
                </Card>
            )
        });
        return (
            <>
                <CardColumns>
                    <Card>
                        <Card.Img src = {logo}/>
                    </Card>
                    {cards}
                </CardColumns>
            </>
        )
    }
    
}

export default withGetScreen(AboutMe);