import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab);

class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            contentCached: false,
            updateAvailable: false
        }
    }

    componentDidMount() {
        // const config = {
        //   onSuccess: this.handleSuccess,
        //   onUpdate: this.handleUpdate
        // }
        // serviceWorker.register(config);
    }

    handleUpdate = () => {
        this.setState({updateAvailable: true});
    }

    handleSuccess = () => {
        this.setState({contentCached: true});
    }

    render() {
        const { contentCached, updateAvailable } = this.state;
        return <App contentCached = {contentCached} updateAvailable = {updateAvailable}/>
    }
}


ReactDOM.render(<Index/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
